import React, {FC} from "react";
import Button from "../button/Button";
import './SceenSizeWarning.css';

interface Props {
    closeFn: () => void;
}

export const ScreenSizeWarning: FC<Props> = ({closeFn}) => {
    return (<div className='screen-size-warning'>
            <div className='screen-size-warning-message'>
                <h1>Let op: Dit scherm is mogelijk te klein!</h1>
                <h1>Deze game is ontworpen voor gebruik op een laptop/desktop computer, en is lastig te spelen op een kleiner scherm.</h1>
                <Button type='primary' onClick={() => {closeFn()}} >Ok</Button>
            </div>
        </div>

    )
}