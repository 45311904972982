import {CareLoadMeterScore} from "../components/careLoadMeter/CareLoadMeter";

export default function computeCareLoadScore(totalUrgencyTooHigh: number) {
    let careLoadScore: CareLoadMeterScore = 'good';
    if (totalUrgencyTooHigh === 1 || totalUrgencyTooHigh === 2) {
        careLoadScore = 'worse';
    } else if (totalUrgencyTooHigh > 2) {
        careLoadScore = 'terrible';
    }
    return careLoadScore;
}