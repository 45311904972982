import {
    CaseData,
    Complaint, FeedbackNode,
    FollowupQuestion, Item,
    Question,
    UrgencyType
} from "./CaseDataInterfaces";
import CaseViewModel, {AudioModel, NotificationModel} from "./CaseViewModel";
import {v4 as uuidv4} from 'uuid';
import {FeedbackLine} from "./game";

// export type InteractionPhase = 'incoming-call'|'incoming-call-accepted'|'case-state-complaint'|'case-state-criteria'|'case-state-end'|'case-state-ended';
export type InteractionPhase = 'context'|'incoming-call'|'case-state-complaint'|'case-state-criteria'|'case-state-end'|'case-state-ended';

export type CaseActionType = 'answer-phone'|'hang-up-phone'|'select-question'|'select-complaint'|'select-abcd'
    |'select-criterium'|'audio-complete'|'audio-request-replay'|'notification-handled'|'next-phase'|'reset'
    |'DEBUG_step'|'DEBUG_cause_urgency'|'minimize-abcd';

export interface CaseAction<PayloadType> {
    type: CaseActionType,
    payload: PayloadType;
}

function updateItemOptionChoices<T>(itemOptionChoices: {[key: string]: T}, item: string, option: T, allowDeselect: boolean = false) {
    const currentOption = itemOptionChoices[item];
    if (option === currentOption) {
        if (allowDeselect) {
            // Option was already set: remove item and return
            const abcdItemOptionChoices =
                Object.assign({}, itemOptionChoices);
            delete abcdItemOptionChoices[item];
            return abcdItemOptionChoices;
        }
        // return the original, as there are no changes
        return itemOptionChoices;
    }
    // add/update item and return
    return Object.assign({}, itemOptionChoices, {[item]: option});
}

// export for testing
export function getFeedbackForQuestionSet(qSet: Question[], selectedIds: string[]): FeedbackLine[] {
    let result: FeedbackLine[] = [];

    function checkAndAddFeedback(question: Question|FollowupQuestion) {
        if (question.feedback) {
            // process feedback
            if (question.score === 'f') {
                // if this question should _not_ have been asked, we add negative feedback if the id is in the selected set
                if (selectedIds.indexOf(question.id) > -1) {
                    result.push({type: 'tip', line: question.feedback.negative})
                } else {
                    result.push({type: 'top', line: question.feedback.positive})
                }
            } else {
                // if this question should have been asked, we add positive feedback if the id is in the selected set
                if (selectedIds.indexOf(question.id) > -1) {
                    result.push({type: 'top', line: question.feedback.positive})
                } else {
                    result.push({type: 'tip', line: question.feedback.negative})
                }
            }
        }
    }

    for (const qOpt of qSet) {
        checkAndAddFeedback(qOpt);
        for (const qFollowOpt of qOpt.followups) {
            checkAndAddFeedback(qFollowOpt);
        }
    }
    return result;
}
export function getFeedbackForComplaint(complaints: Complaint[], selectedIds: string[]): FeedbackLine[] {
    let result: FeedbackLine[] = [];

    // check if incorrect complaints have been selected
    let complaintMap = complaints.reduce((previousValue, currentValue) => {
        previousValue[currentValue.id] = currentValue;
        return previousValue;
    }, {} as {[key: string]: Complaint})

    let wrongChoiceMade = selectedIds.map((id) => complaintMap[id].score).indexOf('f') > -1;
    for (const complaint of complaints) {
        if (complaint.feedback) {
            // process feedback
            // we don't expect the complaint to have feedback for incorrect values
            // the feedback for this is taken from the _correct_ choice
            if (complaint.score !== 'f') {
                if (wrongChoiceMade) {
                    result.push({type: 'tip', line: complaint.feedback.negative})
                } else {
                    result.push({type: 'top', line: complaint.feedback.positive})
                }
            }
        }
    }
    return result;
}
export function getFeedbackForSelectedItemOptions(items: Item[], selectedOptions: { [p: string]: string }): FeedbackLine[] {
    let result: FeedbackLine[] = [];

    for (const item of items) {
        if (item.feedback) {
            // we don't provide feedback for items that don't have a selected option
            // you can only _not_ select an option if the item was not required
            if (selectedOptions.hasOwnProperty(item.text)) {
                let option = item.options.find((itemOption) => itemOption.text === selectedOptions[item.text]);
                if (option) {
                    if (option.score !== 'f') {
                        result.push({type: 'top', line: item.feedback.positive})
                    } else {
                        result.push({type: 'tip', line: item.feedback.negative})
                    }
                }
            }
        }
    }
    return result;
}

type CaseViewUpdateListener = (model: CaseViewModel) => void;

type DebugFeedbackItem = {context: string, feedback: FeedbackNode};

export interface ICaseRunnerDebug extends ICaseRunner{
    debug_handleSetStep(value: InteractionPhase): void;
    debug_reset(): void;
    debug_fillAbcd(): void;
    debug_fillCriteria(): void;
    debug_getAllFeedback(): DebugFeedbackItem[];
}

export interface ICaseRunner {
    handleAction(action: CaseAction<any>, afterActionStateCallback?: (viewModel: CaseViewModel) => void): void;
}

function createMistakeNotification(explanation: string): NotificationModel {
    return {type: "mistake", title: 'fout', body: explanation, new: true, id: uuidv4()};
}

export default class CaseRunner {

    private _data:CaseData;

    private _updateListeners: CaseViewUpdateListener[] = [];

    public viewModel:CaseViewModel;

    constructor(data: CaseData) {
        this._data = data;

        // assign initial viewModel
        this.viewModel = this.initViewModel();
    }

    initViewModel(): CaseViewModel {
        return {
            phase: 'context',
            caseId: this._data.id,
            title: this._data.title,
            level: this._data.level,
            patient: this._data.patient,
            phoneState: 'ringing',
            questionSet1Active: false,
            questionSet2Active: false,
            questionSet3Active: false,
            questionSet1: this._data.content1,
            questionSet1SelectedQuestionIds: [],
            questionSet2: this._data.content2,
            questionSet2SelectedQuestionIds: [],
            questionSet3: this._data.content3,
            questionSet3SelectedQuestionIds: [],
            activeQuestionOptionChoices: {},
            entryComplaintOptionsActive: false,
            entryComplaintOptions: this._data.complaint,
            entryComplaintOptionChoices: {},
            entryComplaintSelectedOptions: [],
            abcdItemsActive: false,
            abcdItemsMinimized: false,
            abcdItems: this._data.abcd,
            abcdItemOptionChoices: {},
            triageCriteriaActive: false,
            triageCriteria: this._data.criteria,
            triageCriteriaOptionChoices: {},
            urgency: 5, // game rules: default U5
            correctUrgency: this._data.urgency,
            audio: undefined,
            notifications: [],
            nextPhaseButtonStatus: 'hidden',
            startTime: undefined,
            takeaway: this._data.takeaway,
            context: this._data.context,
            feedback: [],
        }
    }

    // returns removeListener function
    addViewModelUpdateListener(onUpdate: CaseViewUpdateListener): () => void {
        if (this._updateListeners.includes(onUpdate)) {
            throw new Error('addViewModelUpdateListener() called twice with the same callback function');
        }
        this._updateListeners.push(onUpdate);
        return () => {
            const index = this._updateListeners.indexOf(onUpdate);
            if (index > -1) {
                this._updateListeners.splice(index, 1);
            } else {
                throw new Error('attempt to remove a listener twice');
            }
        }
    }

    computeUrgency():UrgencyType {
        let urgency: UrgencyType = 5;
        // iterate over selected abcd
        for (const abcdItem of this.viewModel.abcdItems) {
            if (this.viewModel.abcdItemOptionChoices.hasOwnProperty(abcdItem.text)) {
                const selectedOptionText = this.viewModel.abcdItemOptionChoices[abcdItem.text];
                const option = abcdItem.options.find(option => option.text === selectedOptionText);
                if (option?.urgency !== undefined && option.urgency < urgency) {
                    urgency = option.urgency;
                }
            }
        }
        // iterate over selected criteria
        for (const criterium of this.viewModel.triageCriteria ?? []) {
            if (this.viewModel.triageCriteriaOptionChoices.hasOwnProperty(criterium.text)) {
                const selectedOptionText = this.viewModel.triageCriteriaOptionChoices[criterium.text];
                const option = criterium.options.find(option => option.text === selectedOptionText);
                if (option?.urgency !== undefined && option.urgency < urgency) {
                    urgency = option.urgency;
                }
            }
        }
        return urgency;
    }

    updateStateAndNotifyListeners() {

        // update values that are derived state
        const oldUrgency = this.viewModel.urgency;
        const newUrgency = this.computeUrgency();
        if (newUrgency !== oldUrgency) {
            this.viewModel = Object.assign({}, this.viewModel, {urgency: newUrgency});
        }

        switch (this.viewModel.phase) {
            case "case-state-complaint":
                if (this.checkSelectedComplaintsComplete() || this.abcdCompleteWithHighUrgency()) {
                    this.viewModel = {...this.viewModel, nextPhaseButtonStatus: 'enabled'}
                } else {
                    this.viewModel = {...this.viewModel, nextPhaseButtonStatus: 'disabled'}
                }
                break;
            case "case-state-criteria":
                if (this.checkCriteriaComplete()) {
                    this.viewModel = {...this.viewModel, nextPhaseButtonStatus: 'enabled'}
                } else {
                    this.viewModel = {...this.viewModel, nextPhaseButtonStatus: 'disabled'}
                }
                break;
        }


        for (const listenerFn of this._updateListeners) {
            listenerFn(this.viewModel);
        }

        // for debugging only
        (window as any).debug_ViewModel = this.viewModel;
    }

    private getActiveQuestions(): Question[] {
        return ([] as Question[]).concat(
            this.viewModel.questionSet1Active ? this.viewModel.questionSet1 : [],
            this.viewModel.questionSet2Active ? this.viewModel.questionSet2 : [],
            this.viewModel.questionSet3Active ? this.viewModel.questionSet3 : [],
        );
    }

    private addSelectedQuestionId(id: string): void {
        if (this.viewModel.questionSet1Active) {
            this.viewModel.questionSet1SelectedQuestionIds.push(id);
        } else if (this.viewModel.questionSet2Active) {
            this.viewModel.questionSet2SelectedQuestionIds.push(id);
        } else if (this.viewModel.questionSet3Active) {
            this.viewModel.questionSet3SelectedQuestionIds.push(id);
        }
    }

    //
    // respond to user actions
    //
    handleAction(action: CaseAction<any>, afterActionStateCallback?: (viewModel: CaseViewModel) => void) {

        switch (action.type) {
            case 'answer-phone':
                this.updateViewModelToPhase('case-state-complaint');
                const basePath = 'data/audio/' + this.viewModel.caseId;
                const audioPath =  basePath + '/' + this._data.intro.id + '.mp3';
                const introAudio = {
                    audioPath,
                    playStatus: 'play'
                };
                this.viewModel = Object.assign({}, this.viewModel, {audio: introAudio});
                break;
            case 'hang-up-phone':
                if (this.viewModel.phase === 'case-state-end') {
                    // we are allowed to exit the conversation
                    this.updateViewModelToPhase("case-state-ended");
                    const endTime = action.payload;
                    const feedback: FeedbackLine[] = [];
                    // sources of feedback
                    // set 1
                    feedback.push(...getFeedbackForQuestionSet(
                        this.viewModel.questionSet1,
                        this.viewModel.questionSet1SelectedQuestionIds));
                    // abcd
                    feedback.push(...getFeedbackForSelectedItemOptions(this.viewModel.abcdItems, this.viewModel.abcdItemOptionChoices));
                    // entryComplaint
                    feedback.push(...getFeedbackForComplaint(
                        this.viewModel.entryComplaintOptions,
                        this.viewModel.entryComplaintSelectedOptions));
                    // set 2
                    feedback.push(...getFeedbackForQuestionSet(
                        this.viewModel.questionSet2,
                        this.viewModel.questionSet2SelectedQuestionIds));
                    // triageCriteria
                    feedback.push(...getFeedbackForSelectedItemOptions(this.viewModel.triageCriteria, this.viewModel.triageCriteriaOptionChoices));
                    // set 3
                    feedback.push(...getFeedbackForQuestionSet(
                        this.viewModel.questionSet3,
                        this.viewModel.questionSet3SelectedQuestionIds));
                    this.viewModel = {...this.viewModel, endTime, feedback};
                } else {
                    const mistakes = this.viewModel.notifications.concat([createMistakeNotification('te vroeg ophangen')]);
                    this.viewModel = Object.assign({} , this.viewModel, {notifications: mistakes})
                }
                break;
            case 'select-question':
                // get audio response
                const selectedQuestionText = action.payload;

                // const selectedQuestionOption = this.viewModel.activeQuestionOptions.find(function (value) {return value.text === selectedQuestionText});
                let selectedQuestionOption;

                // use label to be able to break from inner loop
                const activeQuestions = this.getActiveQuestions();
                outerLoop: for (const qOpt of activeQuestions) {
                    if (qOpt.text === selectedQuestionText) {
                        selectedQuestionOption = qOpt;
                        break;
                    } else {
                        for (const qFollowOpt of qOpt.followups) {
                            if (qFollowOpt.text === selectedQuestionText) {
                                selectedQuestionOption = qFollowOpt;
                                break outerLoop;
                            }
                        }
                    }
                }

                if (selectedQuestionOption !== undefined) {
                    this.viewModel.activeQuestionOptionChoices[selectedQuestionOption.text] = true;
                    if (selectedQuestionOption.score === 'f') {
                        const mistakes = this.viewModel.notifications.concat([createMistakeNotification('verkeerde vraag geselecteerd')]);
                        this.viewModel = Object.assign({} , this.viewModel, {notifications: mistakes})
                    } else {
                        if (selectedQuestionOption.audio !== undefined) {
                            // play response
                            const basePath = 'data/audio/' + this.viewModel.caseId;
                            const audioPath =  basePath + '/' + selectedQuestionOption.audio.id + '.mp3';
                            this.viewModel = Object.assign({}, this.viewModel, {audio: {
                                    audioPath,
                                    playStatus: 'play'
                                }});
                        }
                    }

                    this.addSelectedQuestionId(selectedQuestionOption.id);

                    // if the user selected a correct question in phase 3 the phone state should go to talking-end
                    if (this.viewModel.phase === "case-state-end" && selectedQuestionOption.score === 'g') {
                        this.viewModel = {...this.viewModel, phoneState: 'talking-end', nextPhaseButtonStatus: 'enabled'}
                    }
                } else {
                    console.error('could not find selected question: ', selectedQuestionText);
                }
                break;
            case 'audio-complete':
                const audio = Object.assign({}, this.viewModel.audio, {playStatus: 'finished'});
                this.viewModel = Object.assign({}, this.viewModel, {audio});
                break;
            case 'audio-request-replay':
                const audioReplayPath = action.payload;
                const audioReplay:AudioModel = {...this.viewModel.audio, ...{playStatus: 'play', audioPath: audioReplayPath}};
                this.viewModel = {...this.viewModel, ...{audio: audioReplay}};
                break;
            case 'select-complaint':
                // allow
                const selectedComplaintText = action.payload;
                if (this.viewModel.entryComplaintOptions) {
                    const response = this.viewModel.entryComplaintOptions.find(function (value) {return value.text === selectedComplaintText});
                    if (response !== undefined) {
                        this.viewModel.entryComplaintSelectedOptions.push(response.id);
                        switch (response.score) {
                            case "f":
                                // incorrect!
                                const mistakes = this.viewModel.notifications.concat([createMistakeNotification('verkeerde klacht geselecteerd')]);
                                this.viewModel = Object.assign({} , this.viewModel, {notifications: mistakes})
                                break;
                            case "g":
                                this.updateSelectedComplaint(response);
                                break;
                            case "g_na_abcd":
                                const abcdComplete = this.abcdComplete();
                                if (abcdComplete) {
                                    this.updateSelectedComplaint(response);
                                } else {
                                    const mistakes = this.viewModel.notifications.concat([createMistakeNotification('juiste klacht geselecteerd, maar abcd niet compleet')]);
                                    this.viewModel = Object.assign({} , this.viewModel, {notifications: mistakes})
                                }
                                break;
                        }
                    } else {
                        console.error('could not find selected complaint:', selectedComplaintText);
                    }
                } else {
                    console.error('no complaint options available to handle \'select-complaint\'', selectedComplaintText);
                }
                break;
            case 'select-abcd':
                const abcdItem = action.payload.item;
                const abcdOption = action.payload.option;
                const updatedAbcdItemOptionChoices = updateItemOptionChoices<string>(
                    this.viewModel.abcdItemOptionChoices,
                    abcdItem,
                    abcdOption
                )
                this.viewModel = Object.assign({}, this.viewModel, {abcdItemOptionChoices: updatedAbcdItemOptionChoices});
                break;
            case 'minimize-abcd':
                this.viewModel = {...this.viewModel, abcdItemsMinimized: action.payload};
                break;
            case 'select-criterium':
                const criteriumItem = action.payload.item;
                const criteriumOption = action.payload.option;
                const updatedTriageCriteriaOptionChoices = updateItemOptionChoices<string>(
                    this.viewModel.triageCriteriaOptionChoices,
                    criteriumItem,
                    criteriumOption
                )
                this.viewModel = Object.assign({}, this.viewModel, {triageCriteriaOptionChoices: updatedTriageCriteriaOptionChoices});
                break;
            case 'next-phase':
                if (this.viewModel.phase === 'case-state-complaint') {
                    if (this.abcdCompleteWithHighUrgency()) {
                        // abcd complete and U0 or U1 -> straight to the end screen
                        this.updateViewModelToPhase("case-state-end");
                    } else {
                        this.updateViewModelToPhase("case-state-criteria");
                    }
                } else if (this.viewModel.phase === 'case-state-criteria') {
                    this.updateViewModelToPhase("case-state-end");
                } else if (this.viewModel.phase === 'context') {
                    // context state passes startTime with next-phase action
                    this.viewModel = {...this.viewModel, startTime: action.payload}
                    this.updateViewModelToPhase('incoming-call');
                }
                break;
            case 'notification-handled':
                // shallow copy notifications, update the matching mistake
                const notifications = this.viewModel.notifications.map(item => {
                    if (item.id === action.payload) {
                        return Object.assign({}, item, {new: false});
                    }
                    return item;
                })
                this.viewModel = Object.assign({}, this.viewModel, {notifications});
                break;
            case 'reset':
                this.viewModel = this.initViewModel();
                break;
            case 'DEBUG_step':
                this.debug_handleSetStep(action.payload);
                break;
            case 'DEBUG_cause_urgency':
                this.debug_causeUrgency(action.payload);
                break;
        }
        this.updateStateAndNotifyListeners();

        if (afterActionStateCallback !== undefined) {
            afterActionStateCallback(this.viewModel);
        }
    }

    private abcdCompleteWithHighUrgency() {
        const urgency = this.computeUrgency();
        return this.abcdComplete() && (urgency === 0 || urgency === 1);
    }

    private abcdComplete() {
        return Object.keys(this.viewModel.abcdItemOptionChoices).length === this.viewModel.abcdItems.length;
    }

    private updateSelectedComplaint(response: Complaint) {
        // 'select' the option, this is useful for situations that have 2 options
        const updatedEntryComplaintOptionChoices = updateItemOptionChoices<boolean>(
            this.viewModel.entryComplaintOptionChoices,
            response.id,
            true
        )
        this.viewModel = Object.assign({}, this.viewModel, {entryComplaintOptionChoices: updatedEntryComplaintOptionChoices});
    }

    private checkSelectedComplaintsComplete(): boolean {
        const correctOptionCount = this.viewModel.entryComplaintOptions.filter((complaint) =>
            (complaint.score === 'g' || complaint.score === 'g_na_abcd')).length;
        return this.viewModel.entryComplaintOptions !== undefined
            &&
            Object.keys(this.viewModel.entryComplaintOptionChoices).length === correctOptionCount;
    }

    private checkCriteriaComplete(): boolean {
        // check if all _required_ options have been set.
        if (this.viewModel.triageCriteria) {
            for (const c of this.viewModel.triageCriteria) {
                if (c.required && !this.viewModel.triageCriteriaOptionChoices.hasOwnProperty(c.text)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    updateViewModelToPhase(value: InteractionPhase) {
        switch (value) {
            case "context":
                this.viewModel = {
                    ...this.viewModel,
                    nextPhaseButtonStatus: 'hidden',
                    phase: value,
                };
                break;
            case "incoming-call":
                this.viewModel = {
                    ...this.viewModel,
                    ...{
                        phoneState: 'ringing',
                        questionSet1Active: false,
                        questionSet2Active: false,
                        questionSet3Active: false,
                        abcdItemsActive: false,
                        entryComplaintOptionsActive: false,
                        triageCriteriaActive: false,
                        nextPhaseButtonStatus: 'hidden',
                        phase: value,
                    }
                };
                break;
            case "case-state-complaint":
                this.viewModel = {
                    ...this.viewModel,
                    ...{
                        phoneState: 'talking',
                        questionSet1Active: true,
                        questionSet2Active: false,
                        questionSet3Active: false,
                        abcdItemsActive: true,
                        entryComplaintOptionsActive: true,
                        triageCriteriaActive: false,
                        nextPhaseButtonStatus: 'disabled',
                        phase: value,
                    }
                };
                break;
            case "case-state-criteria":
                this.viewModel = {
                    ...this.viewModel,
                    ...{
                        phoneState: 'talking',
                        questionSet1Active: false,
                        questionSet2Active: true,
                        questionSet3Active: false,
                        abcdItemsActive: true,
                        abcdItemsMinimized: true, // <-- minimize
                        entryComplaintOptionsActive: false,
                        triageCriteriaActive: true,
                        nextPhaseButtonStatus: 'disabled',
                        phase: value,
                    }
                };
                break;
            case "case-state-end":
                this.viewModel = {
                    ...this.viewModel,
                    ...{
                        phoneState: 'talking',
                        questionSet1Active: false,
                        questionSet2Active: false,
                        questionSet3Active: true,
                        abcdItemsActive: true,
                        entryComplaintOptionsActive: false,
                        triageCriteriaActive: true,
                        nextPhaseButtonStatus: 'disabled',
                        phase: value,
                    }
                };
                break;
            case "case-state-ended":
                this.viewModel = {
                    ...this.viewModel,
                    ...{
                        phoneState: 'ended',
                        questionSet1Active: false,
                        questionSet2Active: false,
                        questionSet3Active: false,
                        abcdItemsActive: false,
                        entryComplaintOptionsActive: false,
                        triageCriteriaActive: false,
                        nextPhaseButtonStatus: 'hidden',
                        phase: value,
                    }
                };
                break;
        }
    }
    debug_handleSetStep(value: InteractionPhase) {
        this.updateViewModelToPhase(value);
        this.updateStateAndNotifyListeners();
    }

    debug_reset() {
        this.viewModel = this.initViewModel();
        this.updateStateAndNotifyListeners();
    }

    debug_fillAbcd() {
        const filledOptions: {[key: string]: string} = {};
        for (const item of this.viewModel.abcdItems) {
            if (!this.viewModel.abcdItemOptionChoices.hasOwnProperty(item.text)) {
                filledOptions[item.text] = item.options[0].text;
            } else {
                filledOptions[item.text] = this.viewModel.abcdItemOptionChoices[item.text];
            }
        }
        this.viewModel = {...this.viewModel, abcdItemOptionChoices: filledOptions}
        this.updateStateAndNotifyListeners();

    }
    debug_fillCriteria() {
        const filledOptions: {[key: string]: string} = {};
        for (const item of this.viewModel.triageCriteria) {
            if (!this.viewModel.triageCriteriaOptionChoices.hasOwnProperty(item.text)) {
                filledOptions[item.text] = item.options[0].text;
            } else {
                filledOptions[item.text] = this.viewModel.triageCriteriaOptionChoices[item.text];
            }
        }
        this.viewModel = {...this.viewModel, triageCriteriaOptionChoices: filledOptions}
        this.updateStateAndNotifyListeners();
    }

    /**
     * selects abcd / options to cause a certain urgency if possible, selects nothing if value is not reachable
     *
     * @param urgency
     */
    debug_causeUrgency(urgency: UrgencyType) {
        // iterate over options
        let abcdItemOptionChoices: {[key: string]: string} = {};
        for (const item of this.viewModel.abcdItems) {
            for (const option of item.options) {
                if (option.urgency === urgency) {
                    abcdItemOptionChoices = updateItemOptionChoices<string>(
                        abcdItemOptionChoices,
                        item.text,
                        option.text,
                        false
                    )
                    // next item!
                    break;
                }
            }
        }
        let triageCriteriaOptionChoices: {[key: string]: string} = {};
        for (const item of this.viewModel.triageCriteria) {
            for (const option of item.options) {
                if (option.urgency === urgency) {
                    triageCriteriaOptionChoices = updateItemOptionChoices<string>(
                        triageCriteriaOptionChoices,
                        item.text,
                        option.text
                    )
                    // next item!
                    break;
                }
            }
        }
        this.viewModel = {...this.viewModel, abcdItemOptionChoices, triageCriteriaOptionChoices};
    }

    debug_getAllFeedback(): DebugFeedbackItem[] {
        let result: DebugFeedbackItem[] = [];

        function getFeedbackForQuestionSet(qSet: Question[]): DebugFeedbackItem[] {
            let result: DebugFeedbackItem[] = [];

            function checkAndAddFeedback(question: Question|FollowupQuestion) {
                if (question.feedback) {
                    result.push({context: `vraag: ${question.text} (${question.score})`, feedback: question.feedback});
                }
            }

            for (const qOpt of qSet) {
                checkAndAddFeedback(qOpt);
                for (const qFollowOpt of qOpt.followups) {
                    checkAndAddFeedback(qFollowOpt);
                }
            }
            return result;
        }
        function getFeedbackForComplaint(complaints: Complaint[]): DebugFeedbackItem[] {
            let result: DebugFeedbackItem[] = [];

            for (const complaint of complaints) {
                if (complaint.feedback) {
                    result.push({context: `klacht: ${complaint.text} (${complaint.score})`, feedback: complaint.feedback});
                }
            }
            return result;
        }
        function getFeedbackForSelectedItemOptions(items: Item[]): DebugFeedbackItem[] {
            let result: DebugFeedbackItem[] = [];

            for (const item of items) {
                if (item.feedback) {
                    result.push({context: `item: ${item.text} (${item.options.map((opt) => {return `${opt.text} (${opt.score})`}).join(', ')})`, feedback: item.feedback});
                }
            }
            return result;
        }

        // sources of feedback
        // set 1
        result.push(...getFeedbackForQuestionSet(
            this.viewModel.questionSet1));
        // abcd
        result.push(...getFeedbackForSelectedItemOptions(this.viewModel.abcdItems));
        // entryComplaint
        result.push(...getFeedbackForComplaint(
            this.viewModel.entryComplaintOptions));
        // set 2
        result.push(...getFeedbackForQuestionSet(
            this.viewModel.questionSet2));
        // triageCriteria
        result.push(...getFeedbackForSelectedItemOptions(this.viewModel.triageCriteria));
        // set 3
        result.push(...getFeedbackForQuestionSet(
            this.viewModel.questionSet3));

        return result;
    }
}