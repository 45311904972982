import React, {FC} from "react";
import "./PhoneIcon.css";

export type PhoneIconType = 'ringing'|'ended'|'repeat'|'talking'|'talking-end';

interface Props {
    type: PhoneIconType;
}
export const PhoneIcon: FC<Props> = ({type}) => {
    return <div className={`phone-icon phone-icon-${type}`} ></div>
}