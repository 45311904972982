import {FC} from "react";
import GameLogo from "../gameLogo/GameLogo";
import './LoadingScreen.css';

interface Props {
}
const LoadingScreen: FC<Props> = () => {
    return <div className='loading-screen'>
        <GameLogo size='large'/>
    </div>;
}

export default LoadingScreen;