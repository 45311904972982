import React, {FC} from "react";
import {PatientData} from "../../CaseDataInterfaces";
import "./PatientInfo.css";

interface PatientInfoProps {
    patient: PatientData;
}
const PatientInfo: FC<PatientInfoProps> = ({patient}) => {
    return (<div className='patient-info'>
        <h5 className='case-block-header'>Patiëntgegevens</h5>
        <div className='case-block'>
            <dl>
                <dt>Naam</dt>
                <dd><div className='patient-info-value-short'>{patient.name}</div></dd>
                <dt>Leeftijd</dt>
                <dd><div className='patient-info-value-short'>{patient.age}</div></dd>
                <dt>Geslacht</dt>
                <dd><div className='patient-info-value-short'>{patient.sex}</div></dd>
                <dt>Voorgeschiedenis</dt>
                <dd><div className='patient-info-value-full'>{patient.history}</div></dd>
                <dt>Medicatie</dt>
                <dd><div className='patient-info-value-full'>{patient.medication}</div></dd>
                <dt>Allergieën</dt>
                <dd><div className='patient-info-value-full'>{patient.allergies}</div></dd>
                <dt>Extra informatie</dt>
                <dd><div className='patient-info-value-full'>{patient.extraInfo}</div></dd>
            </dl>
            <div className='patient-info--photo-container'>
                <img src={patient.photo} alt='pasfoto patiënt'/>
            </div>
        </div>
    </div>)
}
export default PatientInfo;