import React, {FC} from "react";
import {NotificationModel} from "../../CaseViewModel";
import './Notification.css';
import Button from "../button/Button";
import pijnladderImage from "./pijnladder.svg";
import regelVanNegenImage from "./regelvannegen.svg";

interface Props {
    model: NotificationModel;
    onCancel?: () => void;
    onOk: () => void;
}
const Notification: FC<Props> = ({model, onOk, onCancel}) => {
    const imageType = model.body?.match(/\[image:\w+]/) ?? false;
    // image options
    // [image:pijnladder]
    // [image:regelvannegen]
    let image = null;
    if (imageType) {
        if (model.body === '[image:pijnladder]') {
            image = <img src={pijnladderImage} alt='pijnladder'/>
        } else if (model.body === '[image:regelvannegen]') {
            image = <img src={regelVanNegenImage} alt='regelvannegen'/>
        }
    }
    return (<div className={`notification notification-${model.type}` + (imageType ? ' notification-special-image' : '') }>
        {
            model.type === 'mistake' ?
                <div className='notification-content notification-content-mistake'>
                    <span style={{fontWeight: 700}}>Helaas</span><br/>
                    Dit is onjuist op dit moment<br/>
                    <span className='notification-mistake-highlight'>+60 seconden</span>
                </div>
                :
                undefined
        }
        {
            model.type === 'general' || model.type === 'item-info' ?
                image !== null ?
                    <div className='notification-content'>
                        {image}
                    </div>
                    :
                    <div className='notification-content'>
                        <h2>{model.title}</h2>
                        {model.body}
                    </div>
                :
                undefined
        }
        <div className='notification-actions'>
            {
                onCancel !== undefined ?
                    <Button type='secondary' onClick={() => {onCancel()}}>Annuleren</Button>
                    :
                    undefined
            }
            <Button type='primary' onClick={() => {onOk()}}>Ok</Button>
        </div>
    </div>)
}
export default Notification;