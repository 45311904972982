import {CaseResult, CaseState} from "../game";
import getMostRecentSufficientUrgencyCaseResult from "./getMostRecentSufficientUrgencyCaseResult";

/**
 * sum of 'wasted' urgency over all cases, we only use the most recent result for each case.
 *
 * @param cases
 * @param results
 */
export default function totalUnnecessaryUrgency(cases: CaseState[], results: CaseResult[]) {
    let count = 0;
    for (const caseState of cases) {
        const result = getMostRecentSufficientUrgencyCaseResult(caseState.caseId, results);
        if (result != null && result.urgencyValue < result.urgencyTargetValue) {
            count += (result.urgencyTargetValue - result.urgencyValue);
        }
    }
    return count;
}
