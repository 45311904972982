import React, {FC, useEffect, useState} from "react";
import './Timer.css';
import splitTimerTime from "../../util/splitTimerTime";

function useCurrentTimeMillis(updateIntervalMillis: number) {
    const [value, setValue] = useState(Date.now());
    useEffect(() => {
        const timerId = setInterval(() => {
            setValue(() => Date.now());
        }, updateIntervalMillis);
        return () => {
            clearInterval(timerId);
        }
    }, [setValue, updateIntervalMillis])
    return value;
}
interface Props {
    startTime?: number;
    endTime?: number;
    extraTime: number;
    extraTimePending: number;
}
const Timer: FC<Props> = ({startTime  , endTime, extraTime= 0, extraTimePending= 0}) => {
    // triggers re-render. Note: this is a hook, so it should not be in a conditional
    const currentTime = useCurrentTimeMillis(1000);
    const compareTime = endTime !== undefined ? endTime : currentTime;

    if (startTime !== undefined ) {
        const millisPassed = compareTime - startTime;
        if (millisPassed >= 0) {
            const totalSeconds = Math.floor(millisPassed / 1000) + extraTime;
            // const minutesPart = Math.floor(totalSeconds / 60);
            // // const minutesPartFormatted = minutesPart < 10 ? '0' + String(minutesPart) : String(minutesPart);
            // const secondsPart = totalSeconds - (minutesPart * 60);
            // const secondsPartFormatted = secondsPart < 10 ? '0' + String(secondsPart) : String(secondsPart);
            const {minutes: minutesPart, seconds: secondsPartFormatted} = splitTimerTime(totalSeconds);
            return (<div className='timer'>
                <div className='timer-minutes'>{minutesPart}</div>
                <div className='timer-divider'>:</div>
                <div className='timer-divider'>{secondsPartFormatted}</div>
                <div className='timer-extra-time-pending'>{
                    extraTimePending > 0 ?
                        '+ ' + extraTimePending
                        :
                        undefined
                }</div>
            </div>)
        }
    }
    return (<div className='timer'>--:--</div>)
}
export default Timer;