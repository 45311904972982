import React, {FC} from "react";
import './CaseEnd.css'
import Button from "../button/Button";
import {PatientData} from "../../CaseDataInterfaces";
import CaseReview from "../caseReview/CaseReview";
import {FeedbackLine} from "../../game";

interface Props {
    patient: PatientData;
    urgencyComparison: number; // correct - actual (but remember, numerical lower value is _higher_ urgency
    exitFn: () => void;
    nextFn: () => void;
    retryFn: () => void;
    levelComplete: boolean;
    takeawayMessage: string;
    feedback?: FeedbackLine[];
}
const CaseEnd: FC<Props> = ({patient, urgencyComparison, exitFn, nextFn, retryFn, levelComplete, takeawayMessage, feedback}) => {
    const defaultActions = <div className='case-end-actions'>
        <Button type='secondary' size='large' onClick={() => {
            exitFn()
        }}>Stoppen</Button>
        <Button type='primary' size='large' onClick={() => {
            nextFn()
        }}>{levelComplete ? 'Bekijk levelscore' : 'Volgende Patiënt'}</Button>
    </div>;
    let retryActions = <Button type='secondary' size='large' onClick={() => {
        retryFn();
    }}>Speel opnieuw</Button>;

    return <CaseReview
        patient={patient}
        urgencyComparison={urgencyComparison}
        takeawayMessage={takeawayMessage}
        defaultActions={defaultActions}
        retryActions={retryActions}
        feedback={feedback}
    />
}

export default CaseEnd;