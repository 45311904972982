import React, {FC} from "react";
import './UrgencyBar.css';

const urgencyOptions = [
    {value: 0, color: '#D83B54'},
    {value: 1, color: '#E48747'},
    {value: 2, color: '#FDF5C2'},
    {value: 3, color: '#D8E9C5'},
    {value: 4, color: '#C1E1F7'},
    {value: 5, color: '#F3F3F3'},
];

const UrgencyBarImpl: FC<{ selected: number }> = ({selected}) => {
    return <div className='urgency-bar'>
        {
            urgencyOptions.map((urgencyOption, urgencyOptionIdx) => {
                const active = urgencyOption.value === selected;
                return <div className={active ? 'urgency-value urgency-value__active' : 'urgency-value'}
                            style={{backgroundColor: active ? urgencyOption.color : 'transparent'}}
                            key={urgencyOptionIdx}>
                    <div className='urgency-value__color-dot' style={{backgroundColor: urgencyOption.color}}></div>
                    <div className='urgency-value__label'>U{urgencyOption.value}</div>
                </div>
            })
        }
    </div>
}

export const UrgencyBar = React.memo(UrgencyBarImpl);
