import {CaseResult, CaseState} from "../game";
import getMostRecentSufficientUrgencyCaseResult from "./getMostRecentSufficientUrgencyCaseResult";
import getAllInsufficientUrgencyCaseResults from "./getAllInsufficientUrgencyCaseResults";

/**
 * count all _latest_ succesful triages + unsuccesful triages (those will have resulted in a returning patient)
 * @param cases
 * @param results
 */
function totalLevelTime(cases: CaseState[], results: CaseResult[]) {
    let seconds = 0;
    for (const caseState of cases) {
        const success = getMostRecentSufficientUrgencyCaseResult(caseState.caseId, results);
        seconds += success?.timeSeconds ?? 0;
        const failures = getAllInsufficientUrgencyCaseResults(caseState.caseId, results);
        for (const failure of failures) {
            seconds += failure.timeSeconds;
        }
    }
    return seconds;
}

export default totalLevelTime;