import React, {FC, useEffect} from "react";
import './reset.css';
import './AppWrap.css';
import updateAppScaleToWidth from "./util/updateAppScaleToWidth";

interface Props {
    children: React.ReactNode;
    scaleToWidth?: number;
}
const AppWrap: FC<Props> = ({children, scaleToWidth}) => {
    useEffect(() => {
        if (scaleToWidth !== undefined) {
            console.log('AppWrap updating scale to width:', scaleToWidth);
            updateAppScaleToWidth(scaleToWidth);
        }
    }, [scaleToWidth]);
    return <div className={`style-constants app-wrap`}>
        {children}
    </div>;
}

export default AppWrap;