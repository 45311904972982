import React, {FC} from "react";
import './AboutScreen.css'
import close from "./close.svg";
import {PageData} from "../../CaseDataInterfaces";
import {BodyContent} from "../bodyContent/BodyContent";
import Logos from "../logos/Logos";

interface Props {
    pageData: PageData;
    onClose: () => void;
}
export const AboutScreen: FC<Props> = ({pageData, onClose}) => {
    return (<div className='about-screen'>
        <div className='about-screen-content'>
            <BodyContent parts={pageData.body} />
            <div className="body-content">
                <h2>Game versie</h2>
                <p>{process.env.REACT_APP_VERSION}</p>
            </div>
        </div>
        <button className='about-screen-close-button' onClick={() => {onClose();}}><img src={close} alt='sluiten' /></button>
        <Logos />
    </div>);
}