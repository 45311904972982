import React, {FC} from "react";
import "./PhoneButton.css";
import offHook from "./off-hook.svg";
import onHook from "./on-hook.svg";

interface Props {
    type: 'off-hook'|'on-hook';
    onClick: (event:React.MouseEvent) => void;
}
export const PhoneButton: FC<Props> = ({type, onClick}) => {
    return <button className={`phone-button phone-button-${type}`} onClick={(evt) => {onClick(evt);}}>
        {
            type === 'off-hook' ?
                <img src={offHook} alt='neem op' />
                :
                <img src={onHook} alt='hang op' />
        }
        <div className='phone-button-pulse' />
    </button>
}