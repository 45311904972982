import React, {FC} from "react";
import './CaseFeedbackScreen.css'
import {CaseResult} from "../../game";
import CaseReview from "../caseReview/CaseReview";
import {useCaseIdToCaseDataMapping} from "../../CaseRunnerContextProvider";
import Button from "../button/Button";
import HeaderBar from "../headerBar/HeaderBar";

interface Props {
    caseResult: CaseResult;
    onClose: () => void;
}

export const CaseFeedbackScreen: FC<Props> = ({caseResult, onClose}) => {

    const caseIdToCaseDataMapping = useCaseIdToCaseDataMapping();

    const caseData = caseIdToCaseDataMapping(caseResult.caseId);

    if (caseData === undefined) {
        return (<div>Onze excuses, er is een onverwachte fout opgetreden</div>);
    }

    const defaultActions = <div className='case-end-actions'>
        <Button type='secondary' size='large' onClick={() => {
            onClose()
        }}>Close</Button>
    </div>;

    return (<div className='case-feedback-screen'>
        <HeaderBar />
        <div className='case-feedback-screen-content'>
            <CaseReview
                patient={caseData.patient}
                urgencyComparison={caseResult.urgencyTargetValue - caseResult.urgencyValue} // viewModel.correctUrgency - viewModel.urgency
                takeawayMessage={caseData.takeaway} // viewModel.takeaway
                defaultActions={defaultActions}
                retryActions={undefined}
                feedback={caseResult.feedback}
            />
        </div>
    </div>);
}