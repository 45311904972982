import React, {FC} from "react";
import './Button.css'

interface Props {
    type?: 'primary'|'secondary';
    disabled?: boolean;
    size?: 'variable'|'large'|'max';
    children: React.ReactNode;
    onClick: (evt: React.MouseEvent) => void;
    margins?: boolean
}
const Button: FC<Props> = ({
                               type = 'primary',
                               disabled= false,
                               size = 'variable' ,
                               children,
                               onClick,
                               margins = true}) => {
    return <button
        className={`button button-${type} button-size-${size}` + (margins ? '' : ' button-no-margin')}
        disabled={disabled}
        onClick={(evt) => {onClick(evt)}}>
        {children}
    </button>;
}

export default Button;