import React, {FC, useState} from "react";
import './LevelEnd.css'
import HeaderBar from "../headerBar/HeaderBar";
import Button from "../button/Button";
import CareLoadMeter from "../careLoadMeter/CareLoadMeter";
import {CaseResult, CaseState, LevelResult} from "../../game";
import {PatientComponent, PatientComponentSize} from "../levelStatus/LevelStatus";
import splitTimerTime from "../../util/splitTimerTime";
import totalUnnecessaryUrgencyCases from "../../util/totalUnnecessaryUrgency";
import computeCareLoadScore from "../../util/computeCareLoadScore";
import InfoButton from "../infoButton/InfoButton";
import {useCaseIdToCaseDataMapping} from "../../CaseRunnerContextProvider";
import getMostRecentSufficientUrgencyCaseResult from "../../util/getMostRecentSufficientUrgencyCaseResult";
import getAllInsufficientUrgencyCaseResults from "../../util/getAllInsufficientUrgencyCaseResults";
import totalLevelTime from "../../util/totalLevelTime";

interface Props {
    nextFn: () => void;
    stopFn: () => void;
    retryFn: () => void;
    showAboutGameFn: () => void;
    levelNumber: number;
    levelState: LevelResult;
    gameComplete: boolean;
    showCaseFeedbackFn: (caseResult: CaseResult) => void;
}

export function buildClickableCaseFeedbackPatientIcon(idx: number, caseStatus: CaseState, results: CaseResult[], showCaseFeedbackFn: (caseResult: CaseResult) => void, size: PatientComponentSize) {
    // find latest result
    const latestCorrectCaseResult = getMostRecentSufficientUrgencyCaseResult(caseStatus.caseId, results);
    return <div className='click-wrap__level-status-patients-patient' key={idx} onClick={() => {

        if (latestCorrectCaseResult === undefined) {
            console.error('no latestCorrectCaseResult for ', caseStatus.caseId);
        } else {
            showCaseFeedbackFn(latestCorrectCaseResult);
        }
    }}>
        <PatientComponent state={caseStatus} size={size} clickable={latestCorrectCaseResult !== undefined} />
    </div>;
}

const LevelEnd: FC<Props> = ({nextFn, stopFn, retryFn, levelNumber, levelState, gameComplete,
                                 showAboutGameFn, showCaseFeedbackFn}) => {
    const cases = levelState.caseStates;
    const results = levelState.results;
    const caseIdToCaseDataMapping = useCaseIdToCaseDataMapping();
    // const totalSeconds = results.reduce((previous, current) => {return previous + current.timeSeconds}, 0);
    const totalSeconds = totalLevelTime(cases, results);
    const {minutes, seconds} = splitTimerTime(totalSeconds);
    const totalLevelTimeFormatted = `${minutes}:${seconds}`;
    const totalUrgencyTooHigh = totalUnnecessaryUrgencyCases(cases, results);
    const [showPatients, setShowPatients] = useState<boolean>(false);
    const [showCareLoad, setShowCareLoad] = useState<boolean>(false);
    const [showTotalTime, setShowTotalTime] = useState<boolean>(false);

    // const averageUrgencyTooHigh = totalCases > 0 ? totalUrgencyTooHigh / totalCases : 0;
    let careLoadScore = computeCareLoadScore(totalUrgencyTooHigh);
    return <div className='level-end'>
        <HeaderBar onAboutGameFn={() => {showAboutGameFn()}} />
        <div className='level-end-message'>Je hebt level {levelNumber} behaald!</div>
        <div className='level-end-summary'>
            <div className='level-end-summary-item level-end-summary-item-patients'>
                <h2>Geholpen patiënten</h2>
                <div className='level-end-patients'>
                {
                    cases.map((caseStatus, idx) => {
                        return buildClickableCaseFeedbackPatientIcon(idx, caseStatus, results, showCaseFeedbackFn, 'feedback');
                    })
                }
                    <div className='level-end-info-wrap'><InfoButton onClick={() => {setShowPatients(true)}} /></div>
                </div>
            </div>
            <div className='level-end-summary-item level-end-summary-item-care-load'>
                <h2>Zorgbelasting</h2>
                <div className='level-end-care-load'>
                    <CareLoadMeter score={careLoadScore} size='medium' />
                </div>
                <div className='level-end-info-wrap'><InfoButton onClick={() => {setShowCareLoad(true)}} /></div>
            </div>
        </div>
        <div className='level-end-summary'>
            <div className='level-end-summary-item level-end-summary-total-time'>
                <div>Jouw totale tijd<br/><br/></div>
                <div className='level-end-summary-total-time-timer'>{totalLevelTimeFormatted}</div>
                <div className='level-end-info-wrap'><InfoButton onClick={() => {setShowTotalTime(true)}} /></div>
            </div>
        </div>
        <div className='level-end-retry-action'>
            <Button type='secondary' size='large' onClick={() => {retryFn()}} >Speel opnieuw</Button>
        </div>
        <div className='level-end-main-actions'>
            <Button type='secondary' size='large' onClick={() => {stopFn()}} >Game stoppen</Button>
            <Button type='primary' size='large' onClick={() => {nextFn()}} >{gameComplete ? 'Bekijk eindscore' : 'Volgende patiënt'}</Button>
        </div>
        {
            showPatients ?
                <div className='level-end-detail-overlay' >
                    <div className='level-end-detail'>
                        <h2>Geholpen patiënten</h2>
                        <div className='level-end-detail-patients'>
                            {
                                cases.map((caseStatus, idx) => {
                                    const caseData = caseIdToCaseDataMapping(caseStatus.caseId);
                                    const latestCorrectCaseResult = getMostRecentSufficientUrgencyCaseResult(caseStatus.caseId, results);
                                    if (latestCorrectCaseResult === undefined) {
                                        console.log('no latestCorrectCaseResult for ', caseStatus.caseId);
                                    }
                                    const urgencyTooHigh = latestCorrectCaseResult !== undefined ?
                                        latestCorrectCaseResult?.urgencyValue < latestCorrectCaseResult?.urgencyTargetValue
                                        :
                                        false
                                    ;
                                    return <div className='level-end-detail-patients-patient' key={idx}>
                                        {buildClickableCaseFeedbackPatientIcon(0, caseStatus, results, showCaseFeedbackFn, 'feedback-detail')}
                                        {
                                            caseData !== undefined ?
                                                <div className='level-end-detail-patients-patient-details'>
                                                    <div>{caseData.patient?.name}</div>
                                                    <div>{
                                                        caseData.complaint
                                                            .filter((complaint) => complaint.score !== 'f')
                                                            .map((complaint, complaintIdx) => <span className='level-end-detail-patients-patient-complaint' key={complaintIdx}>{complaint.text}</span>)
                                                    }</div>
                                                    <div className={'level-end-detail-patients-patient-details-urgency'
                                                    + (urgencyTooHigh ? ' level-end-detail-patients-patient-details-urgency-too-high' : '')}>
                                                        U{latestCorrectCaseResult?.urgencyValue}
                                                    </div>
                                                </div>
                                                :
                                                undefined
                                        }
                                    </div>
                                })
                            }
                        </div>
                        <div className='level-end-detail-actions'>
                            <Button type='primary' onClick={() => {setShowPatients(false)}}>Ok</Button>
                        </div>
                    </div>
                </div>
                :
                undefined
        }
        {
            showCareLoad ?
                <div className='level-end-detail-overlay' >
                    <div className='level-end-detail'>
                        <h2>Zorgbelasting</h2>
                        <div className='level-end-detail-care-load'>
                            <CareLoadMeter score={careLoadScore} size='large' />
                            {
                                careLoadScore === 'good' ?
                                    'Je hebt alle patiënten de juiste urgentie gegeven.'
                                    :
                                    careLoadScore === 'worse' ?
                                        'Je hebt de zorg meer belast dan nodig'
                                        :
                                        'Je hebt de zorg veel meer belast dan nodig'
                            }
                        </div>
                        <div className='level-end-detail-actions'>
                            <Button type='primary' onClick={() => {setShowCareLoad(false)}}>Ok</Button>
                        </div>
                    </div>
                </div>
                :
                undefined
        }
        {
            showTotalTime ?
                <div className='level-end-detail-overlay' >
                    <div className='level-end-detail'>
                        <h2>Jouw totale tijd</h2>
                        <div className='level-end-summary-total-time-timer'>{totalLevelTimeFormatted}</div>
                        <div className='level-end-summary-total-time-norm'>De norm voor de totale tijd van dit level is <b>24</b> minuten.</div>
                        <div className='level-end-detail-patients'>
                            {
                                cases.map((caseStatus, idx) => {
                                    const caseData = caseIdToCaseDataMapping(caseStatus.caseId);
                                    const latestCorrectCaseResult = getMostRecentSufficientUrgencyCaseResult(caseStatus.caseId, results);
                                    if (latestCorrectCaseResult === undefined) {
                                        console.log('no latestCorrectCaseResult for ', caseStatus.caseId);
                                    }
                                    const latestCorrectCaseResultSplit = splitTimerTime(latestCorrectCaseResult?.timeSeconds ?? 0);
                                    const insufficientCaseResults = getAllInsufficientUrgencyCaseResults(caseStatus.caseId, results);
                                    return <div className='level-end-detail-patients-patient' key={idx}>
                                        {buildClickableCaseFeedbackPatientIcon(0, caseStatus, results, showCaseFeedbackFn, 'feedback-detail')}
                                        {
                                            caseData !== undefined ?
                                                <div className='level-end-detail-patients-patient-details'>
                                                    <div>{caseData.patient?.name}</div>
                                                    <div className='level-end-detail-patients-patient-details-time'>
                                                        {
                                                            insufficientCaseResults.map(
                                                                (caseResult, caseResultIdx) =>
                                                                {
                                                                    const split = splitTimerTime(caseResult.timeSeconds);
                                                                    return <div key={caseResultIdx} >
                                                                        {split.minutes}:{split.seconds}
                                                                    </div>
                                                                }
                                                            )
                                                        }
                                                        <div className='level-end-detail-patients-patient-details-time-sufficient-u'>
                                                            {latestCorrectCaseResultSplit.minutes}:{latestCorrectCaseResultSplit.seconds}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                undefined
                                        }
                                    </div>
                                })
                            }
                        </div>
                        <div className='level-end-detail-actions'>
                            <Button type='primary' onClick={() => {setShowTotalTime(false)}}>Ok</Button>
                        </div>
                    </div>
                </div>
                :
                undefined
        }
    </div>;
}

export default LevelEnd;