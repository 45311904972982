import {FC} from "react";
import './CareLoadMeter.css'

export type CareLoadMeterScore = 'good'|'worse'|'terrible';

interface Props {
    score: CareLoadMeterScore;
    size: 'large'|'medium'|'small';
}
const CareLoadMeter: FC<Props> = ({score, size}) => {
    return <div className={`care-load-meter care-load-meter__${size} care-load-meter__${score}`} />;
}

export default CareLoadMeter;