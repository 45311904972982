export default function updateAppScaleToWidth(actualWidth: number, referenceWidth: number = 1024, remToPixelRatio: number = 1, maxScale: number = 1.2) {
    // scale the app to fit inside the window (contained)
    // console.log('innerWidth, innerHeight', window.innerWidth, window.innerHeight);

    // width AND height
    // const heightRatio = window.innerHeight / DEFAULT_VIEW_HEIGHT;
    // const widthRatio = window.innerWidth / DEFAULT_VIEW_WIDTH;
    // document.documentElement.style.fontSize = (REM_TO_PIXEL_RATIO * Math.min(heightRatio, widthRatio)) + 'px';

    // only width
    const widthRatio = (actualWidth !== undefined ? actualWidth : window.innerWidth) / referenceWidth;
    const clampedWidthRatio = Math.min(maxScale, widthRatio);
    document.documentElement.style.fontSize = (remToPixelRatio * clampedWidthRatio) + 'px';
    console.log('updated document font size for rem scaling: ', (remToPixelRatio * clampedWidthRatio), 'px');
}
