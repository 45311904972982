import React, {ReactNode, useContext, useEffect, useState} from "react";
import CaseViewModel from "./CaseViewModel";
import CaseRunner, {ICaseRunner} from "./CaseRunner";
import {CaseData} from "./CaseDataInterfaces";

const CaseRunnerContext = React.createContext<ICaseRunner|undefined>(undefined);
const CaseViewModelContext = React.createContext<CaseViewModel|undefined>(undefined);
export const CasesListContext = React.createContext<CaseData[]|undefined>(undefined);
export const CaseIdToPhotoMappingContext = React.createContext<((id: string) => string | undefined)|undefined>(undefined);
export const CaseIdToCaseDataMappingContext = React.createContext<((id: string) => CaseData | undefined)|undefined>(undefined);

export type CaseRunnerContextProviderProps = {
    children: ReactNode;
    caseRunner: CaseRunner;
    cases: CaseData[];
};

const CaseRunnerContextProvider = (props: CaseRunnerContextProviderProps) => {
    const { children, caseRunner, cases } = props;
    const [state, setState] = useState<CaseViewModel>(caseRunner.viewModel);
    useEffect(() => {
        // 'manually' force a first update
        setState(caseRunner.viewModel);
        return caseRunner.addViewModelUpdateListener((model) => {
            setState(model);
        })
    }, [caseRunner]);

    return <CasesListContext.Provider value={cases}>
        <CaseRunnerContext.Provider value={caseRunner}>
            <CaseViewModelContext.Provider value={state}>
                {children}
            </CaseViewModelContext.Provider>
        </CaseRunnerContext.Provider>
    </CasesListContext.Provider>;
};

export function useCaseRunner() {
    const runner = useContext(CaseRunnerContext);
    if (runner === undefined) {
        throw new Error('useCaseRunner() can only be used in components wrapped in <CaseRunnerContextProvider>')
    }
    return runner;
}
export function useCaseRunnerViewModel() {
    const model = useContext(CaseViewModelContext);
    if (model === undefined) {
        throw new Error('useCaseRunnerViewModel() can only be used in components wrapped in <CaseRunnerContextProvider>')
    }
    return model;
}

export function useCasesList() {
    const cases = useContext(CasesListContext);
    if (cases === undefined) {
        throw new Error('useCasesList() can only be used in components wrapped in <CasesListContext.Provider>')
    }
    return cases;
}

export function useCaseIdToPhotoMapping() {
    const caseIdToPhotoMapping = useContext(CaseIdToPhotoMappingContext);
    if (caseIdToPhotoMapping === undefined) {
        throw new Error('useCaseIdToPhoto() can only be used in components wrapped in <CaseIdToPhotoMappingContext.Provider>')
    }
    return caseIdToPhotoMapping;
}

export function useCaseIdToCaseDataMapping() {
    const caseIdToCaseDataMapping = useContext(CaseIdToCaseDataMappingContext);
    if (caseIdToCaseDataMapping === undefined) {
        throw new Error('caseIdToCaseDataMapping() can only be used in components wrapped in <CaseIdToCaseDataMappingContext.Provider>')
    }
    return caseIdToCaseDataMapping;
}

export default CaseRunnerContextProvider;