import {CaseResult} from "../game";

/**
 * get the most recent result for a case that had a sufficient urgency (either correct or too high)
 * 
 * @param caseId
 * @param results
 */
export default function getMostRecentSufficientUrgencyCaseResult(caseId:string, results: CaseResult[]): CaseResult|undefined {

    const resultsCount = results.length;
    for (let i = resultsCount - 1; i >= 0; i--) {
        const result = results[i];
        if (result.caseId === caseId && result.urgencyTargetValue >= result.urgencyValue) {
            return result;
        }
    }
    return undefined;
}
