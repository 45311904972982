import React, {FC} from "react";
import './BodyContent.css'
import {PageDataPart} from "../../CaseDataInterfaces";

interface Props {
    parts: PageDataPart[];
}
export const BodyContent: FC<Props> = ({parts}) => {
    return (<div className='body-content'>
        {
            parts.map((part, partIdx) => {
                if (part.type === 'h1') {
                    return <h1 key={partIdx}>{part.content}</h1>;
                } else if (part.type === 'h2') {
                    return <h2 key={partIdx}>{part.content}</h2>;
                } else if (part.type === 'h3') {
                    return <h3 key={partIdx}>{part.content}</h3>;
                } else if (part.type === 'p') {
                    return <p key={partIdx}>{part.content}</p>;
                } else {
                    return <div  key={partIdx} className={`body-content-part__${part.type}`}>{part.content}</div>;
                }
            })
        }
    </div>);
}