import React, {FC} from "react";
import './Logos.css';
import logoRadboudumc from './RadboudUMC.png';
import logoScholaMedica from './ScholaMedica.png';
import logoIneen from './ineen.png';
import logoNTS from './NTS.png';

interface Props {
}
const Logos: FC<Props> = () => {
    return (<div className='logos'>
        <img className='logos-logo logos__radboudumc' src={logoRadboudumc} alt='Radboudumc'/>
        <img className='logos-logo logos__schola-medica' src={logoScholaMedica} alt='Schola Medica'/>
        <img className='logos-logo logos__ineen' src={logoIneen} alt='ineen'/>
        <img className='logos-logo logos__nts' src={logoNTS} alt='NTS'/>
    </div>)
}
export default Logos;