import React, {FC, useCallback, useEffect, useRef} from "react";
import {AudioModel} from "../../CaseViewModel";
import {useCaseRunner} from "../../CaseRunnerContextProvider";
import './AudioPlayer.css';
import Button from "../button/Button";

interface AudioPlayerProps {
    audio: AudioModel|undefined;
}

const AudioPlayer: FC<AudioPlayerProps> = ({audio}) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const gameRunner = useCaseRunner();
    const completeCallbackFn = useCallback(() => {
        gameRunner.handleAction({type: 'audio-complete', payload: audio?.audioPath})
    }, [gameRunner, audio?.audioPath]);
    useEffect(() => {
        function complete() {
            completeCallbackFn();
        }
        if (audio !== undefined && audio.playStatus === 'play') {
            if (audio.audioPath) {
                audioRef.current?.addEventListener('ended', complete);
                audioRef.current?.play();
            }

        }

        // keep a reference for cleanup
        const cachedRef = audioRef.current;
        return () => {
            cachedRef?.removeEventListener('ended', complete);
        }
    }, [audio, audio?.audioPath, audio?.playStatus, completeCallbackFn])
    return (<div className='audio-player'>
        {
            audio ?
                <>
                    <audio ref={audioRef} src={audio.audioPath} autoPlay={false} />
                    {
                        audio.playStatus === 'finished' ?
                            <div className='audio-player-repeat-wrap'>
                                <Button type='primary'
                                        size='max'
                                        margins={false}
                                        onClick={() => {
                                            gameRunner.handleAction({
                                                type: 'audio-request-replay',
                                                payload: audio?.audioPath})}}
                                >kunt u dat herhalen?</Button>
                            </div>
                            :
                            <div className='audio-player-waveform-wrap'>
                                <div className='audio-player-waveform' />
                            </div>
                    }
                </>
                :
                undefined
        }
    </div>)
}
export default AudioPlayer;