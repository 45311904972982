import {CaseResultUrgencyScore} from "../game";

/**
 * Note: a _lower_ numerical value is a _higher_ urgency.
 *
 * @param targetValue
 * @param playerValue
 */
export default function computeUrgencyScore(targetValue: number, playerValue: number):CaseResultUrgencyScore {
    let urgencyScore: CaseResultUrgencyScore = 'correct';
    if (targetValue > playerValue) {
        urgencyScore = 'too_high';
    } else if (targetValue < playerValue) {
        urgencyScore = 'too_low';
    }
    return urgencyScore;
}
