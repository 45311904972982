import {FollowupQuestion, Question} from "../../CaseDataInterfaces";
import React, {FC} from "react";
import "./QuestionButton.css";
import tail from './tail.svg';

interface QuestionButtonProps {
    model: Question|FollowupQuestion;
    main: boolean;
    onClick: () => void;
}

const QuestionButton: FC<QuestionButtonProps> = ({model, main, onClick}) => {
    let testElement = undefined;
    if (process.env.REACT_APP_TEST_FEEDBACK) {
        let hasFeedback = model.feedback !== undefined;
        if (model.hasOwnProperty('followups')) {
            let followups = (model as Question).followups;
            testElement = <>{' '}<span className='question-button__debug-feedback'> [{model.score}{hasFeedback ? ' + feedback' : ''}]  {followups.length > 0 ? '→' : ''}</span></>
        } else {
            testElement = <>{' '}<span className='question-button__debug-feedback'> [{model.score}{hasFeedback ? ' + feedback' : ''}]</span></>
        }

    }
    return (
        <button
            className={'question-button' + (main ? '' : ' question-button-followup')}
            onClick={() => (onClick())}>
            {model.text}
            {
                main ?
                    <img src={tail} className="question-button-tail" alt="" />
                    :
                    undefined
            }
            {testElement}
        </button>
    )
}

export default QuestionButton;