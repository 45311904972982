import gameLogo from './game-logo.svg';
import {FC} from "react";
import './GameLogo.css'

interface Props {
    size: 'small'|'medium'|'large';
}
const GameLogo: FC<Props> = ({size}) => {
    return <img className={`game-logo game-logo-${size}`} src={gameLogo} alt='TRIAGE The Game' />;
}

export default GameLogo;