import React, {FC} from "react";
import "./OverlayDialog.css";
import Notification from "../notification/Notification";
import {NotificationModel} from "../../CaseViewModel";

interface ResetWarningProps {
    onOk: () => void;
    onCancel: () => void;
    body: string;
}

const OverlayDialog: FC<ResetWarningProps> = ({onOk, onCancel, body}) => {
    const info: NotificationModel = {
        type: 'general',
        title: 'Resetten',
        body,
        new: false,
        id: '',
    }
    return (
        <div className='overlay-dialog'>
                <Notification model={info}
                              onOk={() => {onOk()}}
                              onCancel={() => {onCancel()}}
                />
        </div>
    )
}

export default OverlayDialog;