import React, {FC} from "react";
import './GameEndScreen.css'
import Button from "../button/Button";
import {CaseResult, LevelResult, TARGET_MAX_SECONDS_PER_CASE} from "../../game";
import CareLoadMeter from "../careLoadMeter/CareLoadMeter";
import totalUnnecessaryUrgency from "../../util/totalUnnecessaryUrgency";
import computeCareLoadScore from "../../util/computeCareLoadScore";
import splitTimerTime from "../../util/splitTimerTime";
import totalLevelTime from "../../util/totalLevelTime";
import {buildClickableCaseFeedbackPatientIcon} from "../levelEnd/LevelEnd";

interface GameEndScreenLevelProps {
    level: LevelResult;
    levelNumber: number;
    showCaseFeedbackFn: (caseResult: CaseResult) => void;
}
const GameEndScreenLevel: FC<GameEndScreenLevelProps> = ({level, levelNumber, showCaseFeedbackFn}) => {
    const results = level.results;
    const cases = level.caseStates;
    const totalSeconds = results.reduce((previous, current) => {return previous + current.timeSeconds}, 0);
    const {minutes, seconds} = splitTimerTime(totalSeconds);
    const totalLevelTime = `${minutes}:${seconds}`;
    const unnecessaryUrgency = totalUnnecessaryUrgency(cases, results);
    const careLoadScore = computeCareLoadScore(unnecessaryUrgency);
    return <div className='game-end-screen-level'>
        <div className='game-end-screen-level-title-container'>
            <div className='game-end-screen-level-title'>Level</div><div className='game-end-screen-level-title-number'>{levelNumber}</div>
        </div>
        <h2>Geholpen patiënten</h2>
        <div className='game-end-screen-level-cases'>
            {
                cases.map((caseStatus, idx) => {
                    return buildClickableCaseFeedbackPatientIcon(idx, caseStatus, results, showCaseFeedbackFn, 'feedback');
                })
            }
        </div>
        <h2>Zorgbelasting</h2>
        <CareLoadMeter score={careLoadScore} size='small' />
        <h2>Jouw totale tijd</h2>
        <div className='game-end-screen-level-total-time-timer'>{totalLevelTime}</div>
    </div>
}


interface Props {
    stopFn: () => void;
    retryFn: () => void;
    levels: LevelResult[];
    showCaseFeedbackFn: (caseResult: CaseResult) => void;
}
const GameEndScreen: FC<Props> = ({
                                      stopFn,
                                      retryFn,
                                      levels,
                                      showCaseFeedbackFn,
}) => {
    const careLoadCanBeImproved = levels.filter((levelResult) => {
        return totalUnnecessaryUrgency(levelResult.caseStates, levelResult.results) > 0
    }).length > 0;
    const timeCanBeImproved = levels.filter((levelResult) => {
        return totalLevelTime(levelResult.caseStates, levelResult.results) > (levelResult.caseStates.length * TARGET_MAX_SECONDS_PER_CASE)
    }).length > 0;

    const splitTargetTimePerCase = splitTimerTime(TARGET_MAX_SECONDS_PER_CASE);

    return <div className='game-end-screen'>
        <h1>Je hebt TRIAGE the game uitgespeeld!</h1>
        <div className='game-end-screen-feedback'>
            <div>
                {
                    careLoadCanBeImproved ? 'Jouw zorgbelasting kan beter.' : ''
                }
            </div>
            {
                timeCanBeImproved ? <div>
                    Jouw tijd is langer dan de streeftijd, hier kan je nog verbeteren.<br/>
                    Een gemiddelde triage is in het echt 7 á 8 minuten, in de game is de generieke streeftijd voor een
                    case {splitTargetTimePerCase.minutes}:{splitTargetTimePerCase.seconds} minuten.
                </div> : ''
            }
            <div>
                {
                    (!careLoadCanBeImproved) && (!timeCanBeImproved) ? 'Je hebt je patiënten goed én snel geholpen en de zorg niet onnodig belast.' : ''
                }
            </div>
            <div className='game-end-screen-feedback__transfer'>
                Je hebt ontdekt welke aspecten van het triage proces je al onder de knie hebt en waar je nog in kan
                verbeteren. Bedenk voor jezelf: Waar ga je mee aan de slag?
            </div>
        </div>
        <div className='game-end-screen-levels'>
            {
                levels.map(
                    (levelData, levelIdx) =>
                        <GameEndScreenLevel key={levelIdx} level={levelData} levelNumber={levelIdx + 1} showCaseFeedbackFn={showCaseFeedbackFn} />
                )
            }
        </div>
        <div className='game-end-screen-actions'>
            <Button type='secondary' size='large' onClick={() => {retryFn()}} >Speel opnieuw</Button>
            <Button type='primary' size='large' onClick={() => {stopFn()}} >Game stoppen</Button>
        </div>
    </div>
}

export default GameEndScreen;