import React, {FC} from "react";
import "./HeaderBar.css";
import Timer from "../timer/Timer";
import CaseViewModel from "../../CaseViewModel";
import GameLogo from "../gameLogo/GameLogo";
import Button from "../button/Button";

interface Props {
    caseState?: CaseViewModel;
    renderLevelStatus?: () => JSX.Element | undefined;
    onAboutGameFn?: () => void;
}
const HeaderBar: FC<Props> = ({caseState, renderLevelStatus, onAboutGameFn}) => {
    return (
        <div className={'header-bar' + (caseState !== undefined ? ' header-bar-case' : '')}>
            <div className='header-bar-logo'>
                <GameLogo size='small' />
            </div>
                    <div className='header-bar-timer'>
            {
                caseState !== undefined && caseState.startTime !== undefined ?
                        <Timer startTime={caseState.startTime}
                               endTime={caseState.endTime}
                               extraTime={caseState.notifications.filter(notification => notification.type === 'mistake' && !notification.new).length * 60}
                               extraTimePending={caseState.notifications.filter(notification => notification.type === 'mistake' && notification.new).length * 60}
                        />
                    :
                    undefined
            }
                    </div>
            <div className='header-bar-level-progress'>
                {
                    renderLevelStatus !== undefined ?
                        renderLevelStatus()
                        :
                        undefined
                }
                {
                    onAboutGameFn !== undefined ?
                        <Button onClick={onAboutGameFn} type='secondary' size='variable' margins={false} >Over deze game</Button>
                        :
                        undefined
                }
            </div>
        </div>
    )
}
export default HeaderBar;