import React, {FC, useEffect, useRef} from "react";

interface Props {
    ring: boolean;
}

const PhoneRinger: FC<Props> = ({ring}) => {
    const audioPath = 'data/audio/sfx/phone_rings.mp3'
    const audioRef = useRef<HTMLAudioElement | null>(null);
    useEffect(() => {
        if (ring && audioRef.current !== null) {
            audioRef.current.loop = true;
            audioRef.current.play();
        }
    }, [audioPath, ring])
    return (<audio ref={audioRef} src={audioPath} autoPlay={false} />)
}
export default PhoneRinger;