import React, {Dispatch, FC, useEffect, useRef, useState} from "react";
import './GameScreen.css'
import HeaderBar from "../headerBar/HeaderBar";
import GameLogo from "../gameLogo/GameLogo";
import Button from "../button/Button";
import {GameAction, GameState, getCurrentCasesFromState} from "../../game";
import LevelStatus from "../levelStatus/LevelStatus";
import {PageData} from "../../CaseDataInterfaces";
import {BodyContent} from "../bodyContent/BodyContent";
import Logos from "../logos/Logos";


interface Props {
    state: GameState;
    startPageData: PageData;
    introPageData: PageData;
    dispatch: Dispatch<GameAction>;
    startNextCaseFn: () => void;
    triggerRestartGameFn: () => void;
}
const GameScreen: FC<Props> = ({state, dispatch, startNextCaseFn, triggerRestartGameFn, startPageData,
                                   introPageData}) => {
    // determine level state
    let currentLevel = (state.levelIdx ?? 0) + 1;
    let currentCases = getCurrentCasesFromState(state);
    const [testAudioPlaying, setTestAudioPlaying] = useState<boolean>(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    useEffect(() => {
        function complete() {
            setTestAudioPlaying(false);
        }
        audioRef.current?.addEventListener('ended', complete);
        // keep a reference for cleanup
        const cachedRef = audioRef.current;
        return () => {
            cachedRef?.removeEventListener('ended', complete);
        }
    });
    let main;
    switch (state.page) {
        case 'start':
            main = <div className='game-screen-main'>
                <GameLogo size='medium' />
                <div className='game-screen-main-message'>
                    <BodyContent parts={startPageData.body} />
                </div>
                <div className='game-screen-main-actions'>
                    <Button type='primary' size='large' onClick={() => {
                        dispatch({type: 'intro'});
                    }} >Start de game</Button>
                </div>
            </div>
            break;
        case 'intro':
            main = <div className='game-screen-main'>
                <GameLogo size='medium' />
                <div className='game-screen-main-message game-screen-main-message-top'>
                    {introPageData.body[0]?.content}
                </div>
                <div>
                    <LevelStatus level={currentLevel} cases={currentCases} />
                </div>
                <div className='game-screen-main-message'>
                    {introPageData.body[1]?.content}
                </div>
                <div>
                    <audio ref={audioRef} src={'/data/audio/test_audio.mp3'} autoPlay={false} />
                    <div className={'game-screen-test-audio' + (testAudioPlaying ? ' game-screen-test-audio-playing' : '')}>
                        <Button type='secondary' size='large' disabled={testAudioPlaying} onClick={() => {
                            setTestAudioPlaying(true);
                            audioRef.current?.play();
                        }} >Test mijn audio</Button>
                    </div>
                </div>
                <div className='game-screen-main-actions'>
                    <Button type='secondary' size='large' onClick={() => {triggerRestartGameFn()}} >Begin opnieuw</Button>
                    <Button type='primary' size='large' onClick={() => {startNextCaseFn()}} >Start de tijd</Button>
                </div>
            </div>
            break;
    }
    return <div className='game-screen'>
        <HeaderBar onAboutGameFn={() => {dispatch({type: 'show-about', payload: true})}} />
        {main}
        <Logos />
    </div> ;
}

export default GameScreen;