import {CaseResult} from "../game";

/**
 * filters results for insufficient urgency, these will have 'returned' later in a level
 *
 * @param caseId
 * @param results
 */
export default function getAllInsufficientUrgencyCaseResults(caseId:string, results: CaseResult[]): CaseResult[] {

    return results.filter((result) => (
        result.caseId === caseId
        &&
        result.urgencyTargetValue < result.urgencyValue
    ));
}
