import React, {FC} from "react";
import "./MinMaxToggleButton.css";

interface Props {
    minimized: boolean;
    onClick: (event:React.MouseEvent) => void;
}
const MinMaxToggleButton: FC<Props> = ({minimized, onClick}) => {
    return <button
        className={'min-max-toggle-button' + (minimized ?  ' min-max-toggle-button-minimized' : '')}
        onClick={(evt) => {onClick(evt);}}
    ></button>
}
export default MinMaxToggleButton;