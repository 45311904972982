import infoSvg from './info-clean.svg';
import {FC} from "react";
import './InfoButton.css'

interface Props {
    onClick: () => void;
}
const InfoButton: FC<Props> = ({onClick}) => {
    return <button className='info-button' onClick={() => {onClick()}}>
        <img className='info-button-image' src={infoSvg} alt='info' />
    </button>;
}

export default InfoButton;