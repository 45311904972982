import React, {FC} from "react";
import './LevelStatus.css';
import patientIcon from "./patient-icon.svg";
import {CaseState} from "../../game";
import {useCaseIdToPhotoMapping} from "../../CaseRunnerContextProvider";
import isDevMode from "../../util/isDevMode";

export type PatientComponentSize = 'header'|'feedback'|'feedback-detail';
export const PatientComponent: FC<{state: CaseState, size: PatientComponentSize, clickable?: boolean}> = ({state, size, clickable}) => {
    const photoMapping = useCaseIdToPhotoMapping();
    const devModeTitle = isDevMode() ? `${state.caseId} - ${state.state}` : undefined;
    return <div className={`level-status-patients-patient level-status-patients-patient-${state.state} level-status-patients-patient-size-${size}` + (clickable ? ' level-status-patients-patient__clickable' : '')}>
        {
            state.state === 'open' ?
                <img src={patientIcon} alt={state.state} title={devModeTitle} />
                :
                state.state === 'active' ?
                    <img src={photoMapping(state.caseId)} alt={state.state} title={devModeTitle} />
                    :
                    <img src={photoMapping(state.caseId)} alt={state.state} title={devModeTitle} />
        }
    </div>
}

interface Props {
    level: number;
    cases: CaseState[];
}
const LevelStatus: FC<Props> = ({ level, cases}) => {
    return <div className='level-status'>
        <div className='level-status-label'>Level</div>
        <div className='level-status-value'>{level}</div>
        <div className='level-status-patients'>
            {
                cases.map((caseStatus, idx) => {
                    return <PatientComponent key={idx} state={caseStatus} size='header' />
                })
            }
        </div>
    </div>;
}

export default LevelStatus;