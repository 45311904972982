import {Reducer} from "react";
import {UrgencyType} from "./CaseDataInterfaces";
import computeUrgencyScore from "./util/computeUrgencyScore";

interface ResultQueueItem {
    levelIdx: number;
    caseIdx: number;
    result: CaseResult;
}

export interface GameState {
    page: 'start'|'intro'|'case'|'level-overview'|'game-overview'|'debug_case_list';
    levelIdx?: number;
    caseIdx?: number;
    levels: LevelResult[];
    queue?: ResultQueueItem[];
    about: boolean;
    compatibility_version?: number;
    caseFeedback?: CaseResult; // if set, show result
}

// Gemiddelde triage in game
export const TARGET_MAX_SECONDS_PER_CASE = 8 * 60;

export interface CaseState {
    caseId: string;
    state: 'open'|'active'|'completed';
}

export interface LevelResult {
    caseStates: CaseState[];
    results: CaseResult[];
}

export type FeedbackLineType = 'top'|'tip';
export interface FeedbackLine {
    type: FeedbackLineType;
    line: string;
}

export type CaseResultUrgencyScore = 'too_high'|'correct'|'too_low';
export interface CaseResult {
    caseId: string;
    urgencyTargetValue: UrgencyType,
    urgencyValue: UrgencyType,
    timeSeconds: number; // seconds
    timestamp: number; // Date.now()
    errorCount: number; // number of times an incorrect / unnecessary action was performed -- used for analytics
    feedback?: FeedbackLine[];
}

export interface GameAction {
    type: 'reset'|'start'|'next-case'|'next-level'|'reset-level'|'case-result'|'process-queued'|'show-about'|'intro'|'show-case-feedback';
    payload?: any;
}

const gameReducerBuilder = (gameLevelStructure: string[][]):Reducer<GameState, GameAction> => {
    function createLevelResult(levelIdx:number): LevelResult {
        return {
            caseStates: gameLevelStructure[levelIdx].map(caseId => ({
                caseId,
                state: 'open'
            })),
            results: []
        };
    }

    const gameReducer: Reducer<GameState, GameAction> = (state: GameState, action: GameAction): GameState => {
        let levels = state.levels ?? [];
        if (levels.length === 0) {
            // init with default first level
            levels = [createLevelResult(0)];
        }
        let levelIdx = state.levelIdx ?? 0;
        let caseIdx = state.caseIdx ?? 0;
        const queue: ResultQueueItem[] = state.queue ?? [];

        function processQueuedItems() {
            let resetQueue = false;
            if (queue !== undefined) {
                for (const resultQueueItem of queue) {
                    const result: CaseResult = resultQueueItem.result;
                    let caseStates = [...levels[resultQueueItem.levelIdx].caseStates];
                    const oldResults = levels[resultQueueItem.levelIdx].results;
                    const urgencyScore = computeUrgencyScore(result.urgencyTargetValue, result.urgencyValue);
                    if (urgencyScore === 'correct' || urgencyScore === 'too_high') {
                        // great / higher load on personnel than needed -> both means patient is happy
                        caseStates[resultQueueItem.caseIdx] = {...caseStates[resultQueueItem.caseIdx], state: 'completed'}
                    } else if (urgencyScore === 'too_low') {
                        // patient will return, delete item, and put at end
                        const item = caseStates.splice(resultQueueItem.caseIdx, 1)[0];
                        caseStates.push({...item});
                    }
                    levels = [...levels];
                    levels[resultQueueItem.levelIdx] = {...levels[resultQueueItem.levelIdx], results: [...oldResults, result], caseStates};
                }
                // clear queue
                resetQueue = true;
            }
            return resetQueue;
        }

        switch (action.type) {
            case 'show-about':
                return {
                    ...state,
                    about: action.payload,
                };
            case 'intro':
                //
                // Process 'old' results if present
                //
                let resetQueueAfterStart3 = processQueuedItems();

                return {
                    ...state,
                    page: 'intro',
                    levels, // potentially updated by processQueuedItems()
                    queue: resetQueueAfterStart3 ? [] : queue
                };
            case 'reset':
                return {
                    ...state,
                    page: 'start',
                    levelIdx: 0,
                    caseIdx: 0,
                    levels: [createLevelResult(0)],
                    queue: [],
                };
            case 'start':
                //
                // Process 'old' results if present
                //
                let resetQueueAfterStart = processQueuedItems();

                return {
                    ...state,
                    page: 'start',
                    levels, // potentially updated by processQueuedItems()
                    queue: resetQueueAfterStart ? [] : queue
                };
            case 'process-queued':
                //
                // Process 'old' results if present
                //
                const queueProcessed = processQueuedItems();
                if (queueProcessed) {
                    return {...state, levels, queue: []};
                }
                // return same state if nothing happened
                return state;
            case 'next-case':
                //
                // Process 'old' results if present
                //
                let resetQueue = processQueuedItems();

                //
                // find the actual next case
                //
                if (levels.length === 0) {
                    // we need to prepare a level 1
                    levels.push(
                        createLevelResult(0)
                    );
                    levelIdx = 0;
                    caseIdx = 0;

                    return {...state, page: 'case', levelIdx, caseIdx, levels, queue: resetQueue ? [] : queue};
                }

                let currentLevel = levels[levels.length -1];
                // check if the current level is done
                if (currentLevel.caseStates.every(state => state.state === 'completed')) {
                    // trigger showing level results
                    state = {...state, levels, page: 'level-overview'}

                    if (resetQueue) {
                        return {...state, levels, queue: []};
                    }
                    return state;
                }

                // current level is not done, find the first case that is not completed (so we include 'open')
                caseIdx = currentLevel.caseStates.findIndex((state) => state.state !== 'completed');
                return {...state, page: 'case', levelIdx, caseIdx, levels, queue: resetQueue ? [] : queue};
            case 'reset-level':
                // clear the current level
                if (levels.length > levelIdx) {
                    levels[levelIdx] = createLevelResult(levelIdx);
                    return {...state, page: 'case', levels, caseIdx: 0};
                }
                // in case of any unexpected situations - don't do anything
                return state;
            case 'next-level':
                //
                // Process 'old' results if present
                //
                let resetQueue2 = processQueuedItems();
                let currentLevel2 = levels[levelIdx];
                if (currentLevel2.caseStates.every(state => state.state === 'completed')) {
                    // create the next level if there is one available
                    if (levels.length < 3) {
                        levelIdx = levels.length - 1;
                        levels.push(
                            createLevelResult(levelIdx + 1)
                        );
                        caseIdx = 0;
                        return {...state, page: 'case', levelIdx: levelIdx + 1, caseIdx, levels, queue: resetQueue2 ? [] : queue};
                    } else {
                        // go to game-overview if all levels have been done
                        return {...state, page: 'game-overview', levelIdx, caseIdx, levels, queue: resetQueue2 ? [] : queue};
                    }
                } else {
                    console.log('unexpected, next-level action but not all cases have been handled?');
                }
                // else... maybe updating the queue?
                if (resetQueue2) {
                    return {...state, levels, queue: []};
                }
                return state;
            case 'case-result':
                // add the result to the process queue, to be processed when 'next-case' happens
                if (state.levelIdx !== undefined && state.caseIdx !== undefined) {
                    const queueItem: ResultQueueItem = {
                        levelIdx: state.levelIdx,
                        caseIdx: state.caseIdx,
                        result: action.payload,
                    };
                    const updatedQueue = [...queue, queueItem];
                    return {...state, queue: updatedQueue};
                }
                break;
            case 'show-case-feedback':
                return {
                    ...state,
                    caseFeedback: action.payload,
                };
        }
        // default
        return state;
    }
    return gameReducer;
}

const initialGameState: GameState = {
    page: 'start',
    levelIdx: undefined,
    caseIdx: undefined,
    levels: [],
    about: false,
}

export const getBestSessionForCaseId: (caseId: string, results: CaseResult[]) => CaseResult|undefined = (caseId, results) => {
    // note: .sort() sorts _in_place_ but the filter() call makes a copy beforehand so that 'fixes' it.
    const orderedCaseResults = results
        .filter((result) => result.caseId === caseId)
        .sort((a, b) => {return a.timeSeconds - b.timeSeconds});

    if (orderedCaseResults.length > 0) {
        return orderedCaseResults[0];
    }
    return undefined;
}

const getCurrentLevelFromState = (state: GameState): LevelResult|undefined => {
    const levelIdx = state.levelIdx ?? 0;
    if (state.levels !== undefined) {
        return state.levels[levelIdx];
    }
}
const getCurrentResultsFromState = (state?: GameState): CaseResult[] => {
    if (state === undefined) {
        return [];
    }
    const levelIdx = state.levelIdx ?? 0;
    if (state.levels !== undefined) {
        return state.levels[levelIdx]?.results ?? [];
    }
    return [];
}
const getCurrentCasesFromState = (state?: GameState): CaseState[] => {
    if (state === undefined) {
        return [];
    }
    const levelIdx = state.levelIdx ?? 0;
    if (state.levels !== undefined) {
        return state.levels[levelIdx]?.caseStates ?? [];
    }
    return [];
}

export {
    gameReducerBuilder,
    initialGameState,
    getCurrentCasesFromState,
    getCurrentResultsFromState,
    getCurrentLevelFromState,
}